<template>
  <div class="relative home">
    <header class="pt-2 pb-6 md:py-4">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <div class="flex flex-col items-center justify-between -mx-4 overflow-hidden md:flex-row">
          <div class="px-4 my-4 overflow-hidden lg:w-1/2">
            <h1 class="logo md:mt-2">
              <img src="images/logo.png">
            </h1>
          </div>
          <div class="px-4 overflow-hidden md:my-4 lg:w-1/2">
            <div class="flex flex-col md:flex-row">
              <a href="https://wa.me/6581391823" target="_blank" class="px-8 py-2 mb-4 text-sm text-white rounded md:py-3 md:mb-0 md:mr-4" style="background-color:#46aa1e">WhatsApp Us Today</a>
              <a href="tel:+6581391823" class="px-8 py-2 text-sm text-white rounded md:py-3" style="background-color:#d23232">Call Us For Free Quote</a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="py-8 text-center text-white bg-siteDBlue lg:hidden">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 my-4 overflow-hidden">
            <h2 class="text-2xl font-bold leading-tight">The Plumber that Solves Your Plumbing Issues</h2>
            <div class="w-full my-6 border-2 border-siteLBlue"></div>
            <h4 class="mb-8 text-lg">More than 15 years experience in the industry, P Plumbing is always committed to being responsible and responsive to all our customers’ issues</h4>
            <a href="https://wa.me/6581391823" class="inline-block px-12 py-4 font-bold text-white uppercase rounded bg-siteBlue">Enquire Now</a>
          </div>
        </div>
      </div>
    </div>
    <img class="lg:hidden" src="images/banner-mobile.jpg">
    <div class="hidden py-20 bg-center bg-cover site-banner lg:block">
      <div class="w-full max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-1/2 px-4 my-4 ml-auto overflow-hidden">
            <div class="p-8 text-white bg-siteDBlue">
              <h2 class="text-2xl font-bold leading-tight lg:text-4xl">The Plumber that Solves Your Plumbing Issues</h2>
              <div class="w-full my-6 border-4 border-siteLBlue"></div>
              <h4 class="mb-8 text-lg">More than 15 years experience in the industry, P Plumbing is always committed to being responsible and responsive to all our customers’ issues</h4>
              <a href="https://wa.me/6581391823" class="inline-block px-12 py-4 font-bold text-white uppercase rounded bg-siteBlue">Enquire Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-8 text-center text-white bg-siteBlue">
      <div class="w-full max-w-screen-md px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 my-4 overflow-hidden">
            <h3 class="mb-4 text-2xl font-bold leading-tight lg:text-3xl">One-Stop <br class="sm:hidden">Plumbing Services</h3>
            <p>We specialise in plumbing issues for both residential and commercial sectors, ranging from sink choke and leak, pipe choke, toilet bowl choke, tap burst, ceiling leak, water tank leak, install water heater, etc.</p>
          </div>
        </div>
      </div>
    </div>
    <div id="our-expertise" class="py-8 text-center text-gray-800">
      <div class="w-full max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 my-4 overflow-hidden">
            <h3 class="mb-4 text-2xl font-bold leading-tight lg:text-3xl">Our Expertise Consist Of</h3>
          </div>
          <div class="w-1/2 px-4 my-4 overflow-hidden lg:w-1/3">
            <img class="mb-4 rounded" src="images/image-01.jpg">
            <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">Sink Choke or Leak</h4>
            <p class="text-sm lg:text-base">Common issue in kitchens and toilets, maybe due to misuse or wear and tear</p>
          </div>
          <div class="w-1/2 px-4 my-4 overflow-hidden lg:w-1/3">
            <img class="mb-4 rounded" src="images/image-02.jpg">
            <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">Toilet Bowl Choke or Leak</h4>
            <p class="text-sm lg:text-base">A problem that best resolve early when detected. We have special equipment to solve choke quickly and effectively</p>
          </div>
          <div class="w-1/2 px-4 my-4 overflow-hidden lg:w-1/3">
            <img class="mb-4 rounded" src="images/image-03.jpg">
            <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">Water Tap Leak & Installation</h4>
            <p class="text-sm lg:text-base">Handle 1,000+ water tap cases; installation, replacement, repair, etc.</p>
          </div>
          <div class="w-1/2 px-4 my-4 overflow-hidden lg:w-1/3">
            <img class="mb-4 rounded" src="images/image-04.jpg">
            <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">Water Heater Installation</h4>
            <p class="text-sm lg:text-base">We have a wide range of water heater and water tank, install with us with no hassle</p>
          </div>
          <div class="w-1/2 px-4 my-4 overflow-hidden lg:w-1/3">
            <img class="mb-4 rounded" src="images/image-05.jpg">
            <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">Ceiling Leakage</h4>
            <p class="text-sm lg:text-base">Another problem that is best resolve early to avoid clogging of water in ceiling which will worsen the issue</p>
          </div>
          <div class="w-1/2 px-4 my-4 overflow-hidden lg:w-1/3">
            <img class="mb-4 rounded" src="images/image-06.jpg">
            <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">Rubbish Chute Replacement</h4>
            <p class="text-sm lg:text-base">We covers repair and installation, ensuring proper sealing and workmanship</p>
          </div>
        </div>
      </div>
    </div>
    <div id="why-choose-us" class="py-8 text-center text-gray-800 bg-gray-200 bg-right-bottom bg-cover lg:text-left lg:py-16 bg-choice">
      <div class="w-full max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 my-4 overflow-hidden lg:w-2/3">
            <h3 class="mb-8 text-2xl font-bold leading-tight lg:text-3xl text-siteBlue">P Plumbing is Your Right Choice</h3>
            <div class="flex flex-col items-center mb-8 lg:flex-row">
              <div class="flex-shrink-0 mb-4 lg:mr-8 lg:mb-0">
                <img src="images/icon-01.png">
              </div>
              <div>
                <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">Quick Response within 1 Hour</h4>
                <p class="text-sm lg:text-base">We understand the urgency of your issues, and with a strong team of backend support and plumbers, we are committed to get in touch with you soonest.</p>
              </div>
            </div>
            <div class="flex flex-col items-center mb-8 lg:flex-row">
              <div class="flex-shrink-0 mb-4 lg:mr-8 lg:mb-0">
                <img src="images/icon-02.png">
              </div>
              <div>
                <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">90 Days Warranty for all Work Done</h4>
                <p class="text-sm lg:text-base">That is our confidence in our work, warranty will be provided for all work done. We are dedicated to provide good workmanship and solve your issues on hand.</p>
              </div>
            </div>
            <div class="flex flex-col items-center mb-8 lg:flex-row">
              <div class="flex-shrink-0 mb-4 lg:mr-8 lg:mb-0">
                <img src="images/icon-03.png">
              </div>
              <div>
                <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">Honest & Transparent Pricing</h4>
                <p class="text-sm lg:text-base">We will inspect, consult, and recommend what is required. Not only that our pricings are transparent, we do what is required. No overcharging.</p>
              </div>
            </div>
            <div class="flex flex-col items-center lg:flex-row">
              <div class="flex-shrink-0 mb-4 lg:mr-8 lg:mb-0">
                <img src="images/icon-04.png">
              </div>
              <div>
                <h4 class="mb-2 text-lg font-bold leading-tight text-siteLBlue lg:text-xl">Experience and Trained Plumbers</h4>
                <p class="text-sm lg:text-base">Quality. Quality. Quality. We use our kitchens and toilets daily, hence our plumbers are all well-trained to ensure your issues don’t resurface.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div id="about-us" class="py-8 text-white bg-siteDBlue">
      <div class="w-full max-w-screen-lg px-4 mx-auto">
            <h3 class="mb-4 text-2xl font-bold leading-tight lg:text-3xl text-center">Solving Plumbing Issue with Technology</h3>
            <p class="mb-4 text-center">There is no plumbing issue that we can’t solve. Our teams of plumber not only based on experience, together with the use of right system, equipment, and technology, we identify and solve your issue quickly and accurately.
            </p>
        <div class="flex flex-wrap items-center -mx-4 overflow-hidden">
          <div class="w-full px-4 my-4 overflow-hidden lg:w-1/3">
                  <h3 class="mb-1 text-lg font-bold leading-tight lg:text-xl text-center">Water Jet System</h3>
                    <p class="mb-4 text-center">Unclogging of mild choke</p>
                  <video width="100%" height="400" controls poster="videos/video1.jpg">
              <source src="videos/video.mp4" type="video/mp4">
            </video>
          </div>
          <div class="w-full px-4 my-4 overflow-hidden lg:w-1/3">
                  <h3 class="mb-1 text-lg font-bold leading-tight lg:text-xl text-center">Choke Grinder</h3>
                    <p class="mb-4 text-center">Unclogging of serious choke</p>
                  <video width="100%" height="400" controls poster="videos/video2.jpg">
              <source src="videos/clog-grinder.mp4" type="video/mp4">
            </video>
          </div>
          <div class="w-full px-4 my-4 overflow-hidden lg:w-1/3">
                  <h3 class="mb-1 text-lg font-bold leading-tight lg:text-xl text-center">Leak Detector</h3>
                    <p class="mb-4 text-center">Accurate detection of concealed leaks</p>
                  <video width="100%" height="400" controls poster="videos/video3.jpg">
              <source src="videos/leak-scanner.mp4" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
    </div>
    <div id="reviews" class="py-8 text-center text-gray-800">
      <div class="w-full max-w-screen-md px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 my-4 overflow-hidden">
            <h3 class="mb-4 text-2xl font-bold leading-tight text-center lg:text-3xl">Reviews from our Customers</h3>
          </div>
          <div class="w-full px-4 my-4 overflow-hidden">
            <carousel :per-page="1" :navigate-to="someLocalProperty" :mouse-drag="true" pagination-padding="10" pagination-active-color="#00a0dc" pagination-color="#0a0541">
              <slide class="text-center">
                <p class="mb-4"><i>"P Plumbing is fast and efficient. Raju did a good job in solving our issue. Recommend to use them for plumbing work"</i></p>
                <p class="font-bold text-siteBlue">- Alvin Kuah</p>
              </slide>
              <slide class="text-center">
                <p class="mb-4"><i>"Raju is fast and polite. Fix the problem in no time. Thank you very much!"</i></p>
                <p class="font-bold text-siteBlue">- Sarah Joy</p>
              </slide>
              <slide class="text-center">
                <p class="mb-4"><i>"P Plumbing did an excellent job. Extremely friendly and will use them again for sure if any problems occur."</i></p>
                <p class="font-bold text-siteBlue">- Gledon Lim</p>
              </slide>
              <slide class="text-center">
                <p class="mb-4"><i>"P Plumbing came quickly when asked for help and resolved the problem very efficiently. Definitely recommended."</i></p>
                <p class="font-bold text-siteBlue">- Felix Matthew</p>
              </slide>
              <slide class="text-center">
                <p class="mb-4"><i>"Very good service from Raju. He is friendly and polite. I request for a replacement of rubbish chute, he came on time and fix the issue on the same day."</i></p>
                <p class="font-bold text-siteBlue">- Peh Yoke Wee</p>
              </slide>
              <slide class="text-center">
                <p class="mb-4"><i>"Very satisfied with Raju immediate, efficient and professional service."</i></p>
                <p class="font-bold text-siteBlue">- Lee Lim Li</p>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <div id="enquiry-form" class="py-8 text-center text-white bg-siteBlue">
      <div class="w-full max-w-screen-md px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 my-4 overflow-hidden">
            <h3 class="mb-4 text-2xl font-bold leading-tight lg:text-3xl">Ease Your Mind, One Step Away from Solving your Issues with P Plumbing</h3>
            <p class="mb-8 text-xl lg:text-2xl">
              Island-Wide Service within 1 Hour<br>
            </p>
            <div class="flex flex-col justify-center md:flex-row">
              <a href="https://wa.me/6581391823" target="_blank" class="px-8 py-2 mb-4 text-sm text-white rounded md:py-3 md:mb-0 md:mr-4" style="background-color:#46aa1e">WhatsApp Us Today</a>
              <a href="tel:+6581391823" class="px-8 py-2 text-sm text-white rounded md:py-3" style="background-color:#d23232">Call Us For Free Quote</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .logo img {
    height: 32px;
  }
  .site-banner {
    background-image: url(/images/banner-desktop.jpg);
  }
  .enquiry-form iframe {
    height: 430px !important;
  }
  @media (min-width: 640px) {
    .logo img {
      height: 36px;
    }
  }
  @media (min-width: 1024px) {
    .bg-choice {
      background-image: url(/images/background-01.png);
    }
  
  }
    video {
      height: 412px!important;
    }
</style>

<script>
  import { Carousel, Slide } from 'vue-carousel'
  import { CollapseTransition } from 'vue2-transitions'
  export default {
    components: {
      CollapseTransition,
      Carousel,
      Slide
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>